import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});
// Add a navigation guard
router.beforeEach((to, from, next) => {
  // Get the role from local storage
  const userRole = localStorage.getItem('hot-user');

  // Check if the route requires a specific role
  if (to.meta.roles && !to.meta.roles.includes(userRole)) {
    return next({ path: '*' });
  }
  // Allow navigation if the role matches
  next();
});

export default router;
