import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import EmailMarketingTemplate from '../views/Super/EmailMarketingTemplate.vue';
import CreateEmailTemplate from '../views/Super/CreateEmailTemplate.vue';

//  admin
const AllCompanies = () => import(/* webpackChunkName: "company" */ '@/views/Super/AllCompanies.vue');
const EditCompany = () => import(/* webpackChunkName: "company" */ '@/views/Super/EditCompany.vue');
const Certificates = () => import(/* webpackChunkName: "cert" */ '@/views/Super/Certificates.vue');
const Account = () => import(/* webpackChunkName: "dashboard" */ '@/views/Super/Account.vue');
const TutorialVideo = () => import(/* webpackChunkName: "tutorial" */ '@/views/Super/TutorialVideo.vue');
const TourPage = () => import(/* webpackChunkName: "tutorial" */ '@/views/Super/TourPage.vue');
const AddTutorialVideo = () => import(/* webpackChunkName: "tutorial" */ '@/views/Super/AddTutorialVideo.vue');
const AddTourVideo = () => import(/* webpackChunkName: "tutorial" */ '@/views/Super/AddTourVideo.vue');
const CreateCertificate = () => import (/* webpackChunkName: "cert" */ '@/views/Super/CreateCertificate.vue');
const Courses = () => import (/* webpackChunkName: "course" */ '@/views/Super/Courses.vue');
const CreateCourse = () => import (/* webpackChunkName: "course" */ '@/views/Super/CreateCourse.vue');
const CourseFolder = () => import (/* webpackChunkName: "course" */ '@/views/Super/CourseFolder.vue');
const CourseFolderDetails = () => import (/* webpackChunkName: "course" */ '@/views/Super/CourseFolderDetails.vue');
const CreateCourseFolder = () => import (/* webpackChunkName: "course" */ '@/views/Super/CreateCourseFolder.vue');
const CertificateDetails = () => import (/* webpackChunkName: "cert" */ '@/views/Super/CertificateDetails.vue');
const CreateCompany = () => import (/* webpackChunkName: "company" */ '@/views/Super/CreateCompany.vue');
const AllUsers = () => import(/* webpackChunkName: "user" */ '@/views/Super/AllUsers.vue');
const CompanyLocations = () => import(/* webpackChunkName: "location" */ '@/views/Super/CompanyLocations.vue');
const AddLocation = () => import(/* webpackChunkName: "location" */ '@/views/Super/AddLocation.vue');
const CompanyCertificates = () => import (/* webpackChunkName: "cert" */ '@/views/Super/CompanyCertificates.vue');
const CompanyCourses = () => import (/* webpackChunkName: "course" */ '@/views/Super/CompanyCourses.vue');
const CompanyCourseFolders = () => import("@/views/Super/CompanyCourseFolders.vue");
const LocationDetails = () => import (/* webpackChunkName: "location" */ '@/views/Super/LocationDetails.vue');
const CourseCatalog = () => import (/* webpackChunkName: "course" */ '@/views/Super/CourseCatalog.vue');
const CompanyCourseDetails = () => import (/* webpackChunkName: "company" */ '@/views/Super/CompanyCourseDetails.vue');
const CourseInstructions = () => import (/* webpackChunkName: "course" */ '@/views/Super/CourseInstructions.vue');
const LessonForm = () => import (/* webpackChunkName: "course" */ '@/views/Super/LessonForm.vue');
const EditCourse = () => import (/* webpackChunkName: "course" */ '@/views/Super/EditCourse.vue');
const EmployeeCertificates = () => import (/* webpackChunkName: "cert" */ '@/views/Super/EmployeeCertificates.vue');
const EmployeeResources = () => import (/* webpackChunkName: "resource" */ '@/views/Super/EmployeeResources.vue');
const EmployeeCourses = () => import (/* webpackChunkName: "course" */ '@/views/Super/EmployeeCourses.vue');
const EmployeeCoursePurchase = () => import(/* webpackChunkName: "course" */ "@/views/Super/EmployeeCoursePurchase.vue");
const AddEmployee = () => import(/* webpackChunkName: "employee" */ '@/views/Super/AddEmployee.vue');
const CreateUser = () => import (/* webpackChunkName: "employee" */ '@/views/Super/CreateUser.vue');
const CompanyEmployees = () => import (/* webpackChunkName: "employee" */ '@/views/Super/CompanyEmployees.vue');
const Resources = () => import(/* webpackChunkName: "resources" */ '@/views/Super/Resources.vue');
const EmployeeDocuments = () => import(/* webpackChunkName: "resource" */ "@/views/Super/EmployeeDocuments.vue");
const LoginReport = () => import (/* webpackChunkName: "resources" */ '@/views/Super/LoginReport.vue');
const ActivityReport = () => import (/* webpackChunkName: "resources" */ '@/views/Super/ActivityReport.vue');
const CourseFailPassReport = () => import (/* webpackChunkName: "resources" */ '@/views/Super/CourseFailPassReport.vue');
const UserOnboardingReport = () => import (/* webpackChunkName: "resources" */ '@/views/Super/UserOnboardingReport.vue');
const AllOnboardingReport = () => import (/* webpackChunkName: "resources" */ '@/views/Super/AllOnboardingReport.vue');

const HrFormReport = () => import (/* webpackChunkName: "resources" */ '@/views/Super/HrFormReport.vue');
// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');

// Pages
const Tour = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Tour.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const RegisterMinLocation = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/RegisterMinLocation.vue');

const UserRegister = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserRegister.vue');
const Signup = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Signup.vue');
const ForgetPassword = () => import (/* webpackChunkName: "employee" */ '@/views/Super/ForgetPassword.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const ResetPassword = () => import (/* webpackChunkName: "employee" */ '@/views/Super/ResetPassword.vue');
const TestQuestionReport = () => import (/* webpackChunkName: "employee" */ '@/views/Super/TestQuestionReport.vue');
const SurveyReport = () => import(/* webpackChunkName: "employee" */ '@/views/Super/SurveyReport.vue');
const SurveySubmissions = () => import (/* webpackChunkName: "employee" */ '@/views/Super/SurveySubmissions.vue');
const SurveySubmissionsDetail = () => import (/* webpackChunkName: "employee" */ '@/views/Super/SurveySubmissionsDetail.vue');
const Marketing = () => import(/* webpackChunkName: "marketing" */ '@/views/Super/Marketing.vue');

//sub-admin
const CreateSubAdmin = () => import (/* webpackChunkName: "employee" */ '@/views/Super/CreateSubAdmin.vue');
// Promo code
const PromoCodes = () => import (/* webpackChunkName: "employee" */ '@/views/Super/PromoCodes.vue');
const CreatePromoCodes = () => import (/* webpackChunkName: "employee" */ '@/views/Super/CreatePromoCodes.vue');
const PromoCodeReport = () => import (/* webpackChunkName: "employee" */ '@/views/Super/PromoCodeReport.vue');

const ScormReport = () => import(/* webpackChunkName: "resources" */ '@/views/Super/ScormReport.vue');
const ScormReportDetails = () => import(/* webpackChunkName: "resources" */ '@/views/Super/ScormReportDetails.vue');

const TabcReport = () => import(/* webpackChunkName: "resources" */ '@/views/Super/TabcReport.vue');
// const Roles = () => import(/* webpackChunkName: "Roles and Permission" */ '@/views/Super/Roles.vue');
// const Permission = () => import(/* webpackChunkName: "Roles and Permission" */ '@/views/Super/Permission.vue');

let authPages = {
	path: '/',
	component: AuthLayout,
	name: 'Authentication',
	children: [
		{
			path: '/home',
			name: 'Home',
			component: Home,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/tour',
			name: 'Tour',
			component: Tour,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/forget_password',
			name: 'ForgetPassword',
			component: ForgetPassword,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/reset_password',
			name: 'ResetPassword',
			component: ResetPassword,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/register',
			name: 'Register',
			component: Register,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/register_1-3_locations',
			name: 'RegisterMinLocation',
			component: RegisterMinLocation,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: '/user_register',
			name: 'User Register',
			component: UserRegister,
			meta: {
				noBodyBackground: true
			}
		},

		{path: '*', component: NotFound}
	]
};

const routes = [
	{
		path: '/',
		redirect: '/login',
		name: 'Login'
	},

	{
		path: '/',
		component: DashboardLayout,
		redirect: '/dashboard',
		name: 'Dashboard',
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: Dashboard
			},
			{
				path: 'all_companies',
				name: 'All Companies',
				component: AllCompanies,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'edit_company',
				name: 'Edit Company',
				component: EditCompany,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','manager','FTM'] }

			},
			{
				path: 'certificates',
				name: 'Certificates',
				component: Certificates,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'courses',
				name: 'Courses',
				component: Courses,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'create_course',
				name: 'CreateCourse',
				component: CreateCourse,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'course_folder',
				name: 'CourseFolder',
				component: CourseFolder,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'course_folder_details',
				name: 'CourseFolderDetails',
				component: CourseFolderDetails
			},
			{
				path: 'create_course_folder',
				name: 'CreateCourseFolder',
				component: CreateCourseFolder,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'certificate_details',
				name: 'CertificateDetails',
				component: CertificateDetails,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager','manager_d_r','general-manager'] }

			},
			{
				path: 'create_company',
				name: 'CreateCompany',
				component: CreateCompany,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'test_question_report',
				name: 'TestQuestionReport',
				component: TestQuestionReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'survey_report',
				name: 'SurveyReport',
				component: SurveyReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'survey_submissions',
				name: 'SurveySubmissions',
				component: SurveySubmissions,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'survey_submissions_detail',
				name: 'SurveySubmissionsDetail',
				component: SurveySubmissionsDetail,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'account',
				name: 'Account',
				component: Account,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager_d_r','general-manager'] }

			},
			{
				path: 'tutorial_video',
				name: 'TutorialVideo',
				component: TutorialVideo
			},
			{
				path: 'add_tutorial_video',
				name: 'AddTutorialVideo',
				component: AddTutorialVideo
			},
			{
				path: 'tour_page',
				name: 'TourPage',
				component: TourPage,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'add_tour_video',
				name: 'AddTourVideo',
				component: AddTourVideo,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'create_certificate',
				name: 'CreateCertificate',
				component: CreateCertificate,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'all_users',
				name: 'All Users',
				component: AllUsers,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: 'create_user',
				name: 'Create Users',
				component: CreateUser,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin']}
			},
			{
				path: 'company_locations',
				name: 'ComapanyLocations',
				component: CompanyLocations,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }
			},
			{
				path: 'add_location',
				name: 'AddLocation',
				component: AddLocation,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }
			},
			{
				path: 'company_certificates',
				name: 'CompanyCertificates',
				component: CompanyCertificates,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }
			},
			{
				path: 'company_courses',
				name: 'CompanyCourses',
				component: CompanyCourses,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }
			},
			{
				path: 'location_details',
				name: 'LocationDetails',
				component: LocationDetails
			},
			{
				path: "company_coursefolders",
				name: "CompanyCourseFolders",
				component: CompanyCourseFolders,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }

			},
			{
				path: 'course_catalog',
				name: 'CourseCatalog',
				component: CourseCatalog,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }
			},
			{
				path: 'company_course_details',
				name: 'CompanyCourseDetails',
				component: CompanyCourseDetails
			},
			{
				path: 'course_instructions',
				name: 'CourseInstructions',
				component: CourseInstructions
			},
			{
				path: 'lesson_form',
				name: 'LessonForm',
				component: LessonForm
			},
			{
				path: 'edit_course',
				name: 'EditCourse',
				component: EditCourse,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
			{
				path: 'employee_certificates',
				name: 'EmployeeCertificates',
				component: EmployeeCertificates,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager','employee'] }

			},
			{
				path: 'employee_resources',
				name: 'EmployeeResources',
				component: EmployeeResources,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager','employee'] }
			},
			{
				path: "employee_documents",
				name: "EmployeeDocuments",
				component: EmployeeDocuments,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager','employee'] }
			},
			{
				path: 'employee_courses',
				name: 'EmployeeCourses',
				component: EmployeeCourses,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager','employee'] }

			},
			{
				path: "employee_course_purchase",
				name: "EmployeeCoursePurchase",
				component: EmployeeCoursePurchase,
			},
			{
				path: '/add_employee',
				name: 'AddEmployee',
				component: AddEmployee,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','company-admin','FTM','manager','manager_d_r','employee'] }
			},
			{
				path: '/company_employees',
				name: 'CompanyEmployees',
				component: CompanyEmployees,
        meta: { requiresAuth: true, roles:  ['company-admin','FTM','manager','manager_d_r','general-manager'] }


			},
			{
				path: '/resources',
				name: 'Resources',
				component: Resources,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }

			},
      {
				path: '/marketing',
				name: 'marketing',
				component: Marketing
			},
			{
				path: '/email_template',
				name: 'emailTemplate',
				component: EmailMarketingTemplate
			},
			{
				path: '/create_email_template',
				name: 'emailTemplate',
				component: CreateEmailTemplate
			},
			{
				path: '/login_report',
				name: 'LoginReport',
				component: LoginReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager_d_r','manager'] }
			},
			{
				path: '/activity_report',
				name: 'ActivityReport',
				component: ActivityReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager_d_r','manager'] }
			},
			{
				path: "/documents-report",
				name: "Documents Report",
				component: () => import("@/views/Common/DocumentsReport.vue"),
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager_d_r','manager'] }
			},
			{
				path: '/course_fail_pass_report',
				name: 'CourseFailPassReport',
				component: CourseFailPassReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager_d_r','manager'] }

			},
			{
				path: '/onboarding_report',
				name: 'OnboardingReport',
				component: UserOnboardingReport
			},

			{
				path: '/all_onboarding_report',
				name: 'AllOnboardingReport',
				component: AllOnboardingReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: '/hrform_report',
				name: 'HrFromReport',
				component: HrFormReport
			},
			{
				path: '/create_subadmin',
				name: 'CreateSubAdmin',
				component: CreateSubAdmin,
        meta: { requiresAuth: true, roles:  ['super-admin'] }

			},
			{
				path: '/promo_codes',
				name: 'PromoCodes',
				component: PromoCodes,
        meta: { requiresAuth: true, roles:  ['super-admin','sub-admin'] }
			},
			{
				path: '/create_promo_code',
				name: 'CreatePromoCodes',
				component: CreatePromoCodes,
        meta: { requiresAuth: true, roles:  ['super-admin','sub-admin'] }
			},
			{
				path: '/promo_code_report',
				name: 'PromoCodeReport',
				component: PromoCodeReport,
        meta: { requiresAuth: true, roles:  ['super-admin','sub-admin'] }
			},
			{
				path: '/scorm-report',
				name: 'ScormReport',
				component: ScormReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin','FTM','company-admin','manager_d_r','manager'] }
			},
			{
				path: '/tabc-report',
				name: 'TabcReport',
				component: TabcReport,
        meta: { requiresAuth: true, roles:  ['super-admin', 'sub-admin'] }
			},
			{
				path: '/scorm-report/details/employee/:employee_id/course/:course_id',
				name: 'ScormReportDetails',
				component: ScormReportDetails
			},
			{
				path: '/scorm-report',
				name: 'ScormReport',
				component: ScormReport
			},
			// {
			// 	path: '/role',
			// 	name: 'Roles',
			// 	component: Roles
			// },
			// {
			// 	path: '/permission',
			// 	name: 'Permission',
			// 	component: Permission
			// },



		]
	},
	authPages,
];

export default routes;
