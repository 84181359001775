<script>
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Select,
  Option,
} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      loading: false,
      promo_code_id: "",
      promocode: {
        promo_code: "",
        description: "",
        percentage: "",
        valid_upto: "",
        status: true,
      },
      errors: {
      promo_code: "",
      percentage: "",
      valid_upto: "",
    },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.promo_code_id = this.$route.query.id;

      this.$http
        .get("promocode/edit_promo_code/" + this.promo_code_id)
        .then((resp) => {
          let data = resp.data;
          this.promocode.promo_code = data.name;
          this.promocode.description = data.description;
          this.promocode.percentage = data.percentage;
          this.promocode.valid_upto = data.validity;
          this.promocode.status = data.status ? true : false;
        });
    }
  },
  methods: {
    validateForm() {
      this.errors = { promo_code: "", percentage: "", valid_upto: "" }; // Reset errors

      let hasError = false;
      const today = new Date().setHours(0, 0, 0, 0); // Current date without time

      if (this.promocode.promo_code === "") {
        this.errors.promo_code = "Promo code is required.";
        hasError = true;
      }
      if (this.promocode.percentage === "") {
        this.errors.percentage = "Percentage is required.";
        hasError = true;
      }
      if (this.promocode.valid_upto === "") {
        this.errors.valid_upto = "Valid until date is required.";
        hasError = true;
      } else if (new Date(this.promocode.valid_upto).setHours(0, 0, 0, 0) < today) {
        this.errors.valid_upto = "Date must be today or in the future.";
        hasError = true;
      }

      return !hasError;
    },

    savePromoCode() {
      if (!this.validateForm()) {
        Swal.fire({
          title: "Error!",
          text: "Please fill all required fields.",
          icon: "error",
        });
        return;
      }
      if (this.promocode.valid_upto != "") {
        if (this.promo_code_id !== "") {
          this.loading = true;
          this.$http
            .put("promocode/update_promo_code/" + this.promo_code_id, {
              promo_code: this.promocode.promo_code,
              description: this.promocode.description,
              percentage: this.promocode.percentage,
              valid_upto: this.promocode.valid_upto,
              status: this.promocode.status ? 1 : 0,
            })
            .then((resp) => {
              Swal.fire({
                title: "Success!",
                text: `Promo Code updated successfully.`,
                icon: "success",
              });
              this.$router.push("/promo_codes");
            }).catch((error) => {
            if (error.response && error.response.status === 422) {
              const errors = error.response.data.errors;
              let errorMessage = 'Please fix the following errors:\n\n';

              for (const key in errors) {
                if (errors[key]) {
                  // Update the local error object to show errors next to fields
                  this.errors[key] = errors[key].join(", ");
                  // Append to the error message
                  errorMessage += `${errors[key].join(", ")}\n`;
                }
              }

              Swal.fire({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
              });
            } else {
              // Handle other types of errors
              Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred.",
                icon: "error",
              });
            }
          })
            .finally(() => (this.loading = false));
        } else {
          this.loading = true;
          this.$http
            .post("promocode/save_promo_code", {
              promo_code: this.promocode.promo_code,
              description: this.promocode.description,
              percentage: this.promocode.percentage,
              valid_upto: this.promocode.valid_upto,
              status: this.promocode.status ? 1 : 0,
            })
            .then((resp) => {
              Swal.fire({
                title: "Success!",
                text: `Promo Code saved successfully.`,
                icon: "success",
              });
              this.$router.push("/promo_codes");
            }).catch((error) => {
            if (error.response && error.response.status === 422) {
              const errors = error.response.data.errors;
              let errorMessage = 'Please fix the following errors:\n\n';

              for (const key in errors) {
                if (errors[key]) {
                  // Update the local error object to show errors next to fields
                  this.errors[key] = errors[key].join(", ");
                  // Append to the error message
                  errorMessage += `${errors[key].join(", ")}\n`;
                }
              }

              Swal.fire({
                title: "Validation Error",
                text: errorMessage,
                icon: "error",
              });
            } else {
              // Handle other types of errors
              Swal.fire({
                title: "Error!",
                text: "An unexpected error occurred.",
                icon: "error",
              });
            }
          })
            .finally(() => (this.loading = false));
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: `Please fill all required feilds.`,
          icon: "error",
        });
      }
    },
  },
};
</script>
<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Card header -->
        <h2 slot="header" class="mb-0" v-if="promo_code_id">Edit Promo Code</h2>
        <h2 slot="header" class="mb-0" v-else>Add Promo Code</h2>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(savePromoCode)"
            enctype="multipart/form-data"
          >
            <div class="form-row">
              <div class="col-md-6">
                <label class="form-control-label"
                  >Promo Code <span class="requireField">*</span></label
                >
                <!-- <base-input -->
                 <input type="text"
                 name="Promo code"
                 class="form-control"
                 placeholder="Promo Code"
                 v-model="promocode.promo_code"
                 >

                <!-- </base-input> -->
                <span v-if="errors.promo_code" class="text-danger mb-1 text-sm">{{ errors.promo_code }}</span>

              </div>

              <div class="col-md-6">
                <label class="form-control-label">Description</label>
                <textarea
                  placeholder="Promo Code Description"
                  class="form-control"
                  v-model="promocode.description"
                ></textarea>
              </div>
              <div class="col-md-6 mb-2">
                <label class="form-control-label"
                  >Percentage (%) <span class="requireField">*</span></label
                >
                <input
                  type="number"
                  name="Percentage"
                  placeholder="Percentage"
                  max="100"
                  min="0"
                  class="form-control"
                  v-model="promocode.percentage"
                >
                <!-- </base-input> -->
                <span v-if="errors.percentage" class="text-danger text-sm">{{ errors.percentage }}</span>
              </div>
              <div class="col-md-6 mb-2">
                <label class="form-control-label"
                  >Valid Until <span class="requireField">*</span></label
                >
                <el-date-picker
                  v-model="promocode.valid_upto"
                  placeholder="Pick a date"
                  style="width: 100%"
                  format="MM/dd/yyyy"

                >
                </el-date-picker>
                <span v-if="errors.valid_upto" class="text-danger text-sm">{{ errors.valid_upto }}</span>
              </div>

              <div class="col-md-6">
                <label class="form-control-label">Status</label>
                <div class="d-flex">
                  <base-switch
                    class="mr-1"
                    type="success"
                    v-model="promocode.status"
                  ></base-switch>
                </div>
              </div>
            </div>
            <div class="text-right mt-2">
              <base-button
                class="custom-btn"
                type="danger"
                @click="$router.go(-1)"
                >Cancel</base-button
              >
              <base-button class="custom-btn" native-type="submit">{{
                promo_code_id !== "" ? " Update" : "Submit"
              }}</base-button>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
  </div>
</template>

<style></style>
